@keyframes anim {
    0% {
      stroke: #9100ff;
      stroke-width: 2px;
      stroke-dashoffset: -2400;
    }
    50% {
      stroke: #20066e;
      stroke-width: 1px;
    }
    75% {
      stroke: #e100ff;
      stroke-width: 2px;
    }
    100% {
       stroke: #ff0099;
       stroke-width: 1px;
       stroke-dashoffset: 0;
    }
  }

@keyframes text {
    0% {
      color: #9100ff;
    }
    50% {
      color: #20066e;
    }
    75% {
      color: #e100ff;
    }
    100% {
       color: #ff0099;
    }
}

@keyframes progressbar {
    0% {
      background: #9100ff;
    }
    15% {
      background: #20066e;
    }
    30% {
      background: #00ffff;
    }
    45% {
      background: #00ff00;
    }
    60% {
      background: #ffff00;
    }
    75% {
      background: #ff5f1f;
    }
    90% {
      background: #f72119;
    }
    95% {
      background: #e100ff;
    }
    100% {
       background: #ff0099;
    }
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg); 
  }
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg); 
  }
}

@keyframes glitchBottom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg); 
  }
}

